<template>
  <div v-if="Object.keys(configFields).length > 0" class="split-bg">
    <!--begin::Main-->
    <div class="d-flex flex-column flex-root">
      <!--begin::Register-->
      <div class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" id="kt_login">
        <!--begin::Aside-->
        <div class="login-aside flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10 bg-primary d-none d-lg-flex min-vh-100 max-w-50">
          <!--begin: Aside Container-->
          <div class="d-flex flex-row-fluid flex-column justify-content-between">
            <!--begin: Aside header-->
            <a href="#" class="flex-column-auto mt-5 pb-lg-0 pb-10">
              <img :src="logo.url" class="h-70 max-h-70px" alt="" />
            </a>
            <!--end: Aside header-->
            <!--begin: Aside content-->
            <div class="flex-column-fluid d-flex flex-column justify-content-center">
              <h3 class="font-size-h1 font-weight-bolder mb-5 text-white">
                {{ $t('signup.title') }}
              </h3>
              <p class="text-white opacity-80">
                {{ $t('signup.sub_title') }}
              </p>
            </div>
            <!--end: Aside content-->
            <!--begin: Aside footer for desktop-->
            <div class="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
              <div class="opacity-70 font-weight-bold text-white">
                {{ new Date().getFullYear() }}{{ $t('copyright.title') }}
              </div>
              <div class="d-flex">
                <!--<a href="#" class="text-white">Политика конфиденциальности</a>-->
              </div>
            </div>
            <!--end: Aside footer for desktop-->
          </div>
          <!--end: Aside Container-->
        </div>
        <!--begin::Aside-->
        <!--begin::Content-->
        <div class="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden min-vh-100">
          <!--begin::Content header-->
          <div class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
            <span class="font-weight-bolder text-dark-50">
              {{ $t('signup.have_account') }}
            </span>
            <a href="/login" class="font-weight-bolder ml-2" id="kt_login_signup">
              {{ $t('login.login') }}
            </a>
          </div>
          <!--end::Content header-->
          <div v-if="error" class="alert alert-danger" role="alert">
            {{error}}
          </div>
          <!--begin::Content body-->
          <div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
            <!--begin::Signin-->
            <div class="login-form login-signin max-w-400px">
              <div class="text-center mb-10 mb-lg-20">
                <h3 class="font-size-h1 font-weight-bolder">
                  {{ $t('login.register') }}
                </h3>
                <p class="text-muted font-weight-bolder">
                  {{ $t('signup.hint') }}
                </p>
              </div>

              <!--begin::Form-->
              <ValidationObserver v-slot="{ invalid }">
              <form v-if="!isAuthenticated && fields" class="form" id="kt_login_signin_form"
                    ref="form" @keypress.enter.prevent="register">
                <template v-for="(field, index) in configFields">
                  <div v-if="field.type == 'text'" class="form-group" :key="index">
                    <ValidationProvider :name="fields[index]" :rules="{required: field.require}" :key="index" v-slot="{ errors }">
                      <label>{{setCurrentLanguage(field)}}<span class="text-danger" v-if="field.require">*</span>:</label>
                      <input class="form-control form-control-solid h-auto" :type="field.name === 'E-mail' ? 'email' : field.type"
                            :class="{ 'is-invalid': errors[0] }"
                            :placeholder="setCurrentLanguage(field)" autocomplete="off" v-model="fields[index]"
                            v-on:keyup.enter="register"/>
                      <span class="text-danger">{{ $i18n.locale === 'en' && errors[0] ? $t('signup.error_message') : errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div v-if="field.type == 'phone'" class="form-group" :key="index">
                    <ValidationProvider :name="fields[index]" :rules="{required: field.require}" :key="index" v-slot="{ errors }">
                      <label>{{setCurrentLanguage(field)}}<span class="text-danger" v-if="field.require">*</span>:</label>
                      <vue-tel-input
                        ref="phoneNumber"
                        class="form-control form-control-solid h-auto input-color"
                        :class="{ 'is-invalid': errors[0] }"
                        v-model="fields[index]"
                        v-bind="bindProps"
                        :placeholder="setCurrentLanguage(field)"
                        @focus="onFocus"
                        v-on:keyup.enter="register"></vue-tel-input>
                        <span class="text-danger">{{ $i18n.locale === 'en' && errors[0] ? $t('signup.error_message') : errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div v-if="field.type == 'checkbox'" class="form-group" :key="index">
                    <ValidationProvider :name="fields[index]" :rules="{required: { allowFalse: field.require }}" :key="index" v-slot="{ errors }">
                      <label v-if="field.type == 'checkbox'" class="mb-0">
                        <input :type="field.type" v-model="fields[index]"/>
                        <span class="mr-3"></span>
                        <span v-html="field.name"></span>
                        <span class="text-danger" v-if="!field.require">*</span>
                        <!-- <span>Я принимаю условия <a class="font-weight-bolder" href="#">Политики конфиденциальности</a> и даю своё согласие на обработку моей персональной информации.</span> -->
                      </label>
                      <br>
                      <span class="text-danger">{{ $i18n.locale === 'en' && errors[0] ? $t('signup.error_message') : errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </template>
                <!--begin::Action-->
                <div class="form-group d-flex flex-wrap flex-center">
                  <button v-if="!loading" @click="register" :disabled="invalid" type="button" id="kt_login_signup_submit"
                          class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4">{{ $t('signup.done') }}</button>
                  <button v-if="loading" @click="register" type="button"
                          class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4" disabled>{{ $t('login.loading') }}</button>
                  <a href="/login" type="button" id="kt_login_signup_cancel"
                     class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">{{ $t('signup.login_page') }}</a>
                </div>
                <!--end::Action-->
              </form>
              <button v-else type="button"
                      class="btn btn-info font-weight-bold px-9 py-4 my-3"
                      @click="goHome">{{ $t('login.log_in') }}</button>
              <!--end::Form-->
              </ValidationObserver>
            </div>
            <!--end::Signin-->
          </div>
          <!--end::Content body-->
          <!--begin::Content footer for mobile-->
          <div class="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
            <div class="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">{{ new Date().getFullYear() }}{{ $t('copyright.title') }}</div>
            <div class="d-flex order-1 order-sm-2 my-2">
              <!--<a href="#" class="text-dark-75 text-hover-primary">Политика конфиденциальности</a>-->
            </div>
          </div>
          <!--end::Content footer for mobile-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Register-->
    </div>
    <!--end::Main-->
  </div>
</template>


<script>
import {mapMutations, mapGetters} from 'vuex'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import api from '@/plugins/api'

let cookie = require('vue-cookie');

let bg = require('@/assets/bg/bg-4.jpg')

extend('required', {
  ...required,
  message: 'Это поле обязательно для заполнения'
});

export default {
  computed: {
    ...mapGetters({
      isAuthenticated: 'user/isAuthenticated',
      configFields: 'main/registration',
      token: 'user/token',
      identity: 'user/identity',
      logo: 'main/logo',
      selfRegistration: 'main/selfRegistration'
    })
  },
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    let cfg = this.$store.getters['main/registration']
    let fields = {}
    for (let i in cfg) {
      // if (cfg[i].type == 'phone') {
      //   fields[i] = {};
      //   fields[i].code = '+7';
      //   fields[i].phone = null;
      // }
      // else {
        fields[i] = null;
      // }
    }
    return {
      loading: false,
      bg: bg,
      fields: fields,
      valid: false,
      error: null,
      bindProps: {
        mode: "international",
        preferredCountries: ["RU", "KZ", "IL"],
        inputOptions: {
          showDialCode: true,
          autofocus: true
        }
      }
    }
  },
  mounted() {
    if (!this.selfRegistration) this.$router.push('/login');
  },
  watch: {
    token (to, from) {
      // обрабатываем изменение параметров маршрута...
      console.log("isAuthenticated", to, from)
      if (to) {
        self.$router.push('/')
      }
    },
    selfRegistration (newVal) {
      if (newVal === false) this.$router.push('/login');
    }
  },
  methods: {
    setCurrentLanguage(item) {
      if (this.$i18n.locale === 'en') {
        if (item.name === 'Имя') return 'First name'
        if (item.name === 'Фамилия') return 'Second name'
        if (item.name === 'Отчество') return 'Middle name'
        if (item.name === 'E-mail') return 'E-mail'
        if (item.name === 'Телефон') return 'Phone'
        if (item.name === 'Пароль') return 'Password'
      } else {
        return item.name
      }
    },
    onFocus() {
      const input = this.$refs.phoneNumber[0].$el.querySelector('input');
      const end = input.value.length;
      this.$nextTick(() => {
        input.setSelectionRange(end, end);
        input.focus();
      })
    },
    goHome() {
      this.loading = false
      this.$router.push('/')
    },
    reset() {
      this.loading = false
      this.$refs.form.reset()
    },
    async register() {
      let self = this
      let cfg = this.$store.getters['main/registration']
      this.loading = true
      self.error = null;
      let data = {}

      for (let i in cfg) {
        if (cfg[i].type == 'phone' && self.fields[i]) {
          // data[i] = self.fields[i].code + self.fields[i].phone;
          self.fields[i] = self.fields[i].replace(/[\s\-\(\)]+/gi, '')
        }
        // else {
          data[i] = self.fields[i];
        // }
        // if (cfg[i].require && !data[i]) {
        //   self.error = 'Заполните обязательные поля'
        // }
      }
      if (self.error) {
        this.loading = false
        return this
      }
      api.post('/v2/auth/register', data)
        .then(function (responce) {
          // console.log('Load data', responce.data)
          // self.$store.commit('user/UpdateIdentity', responce.data)
          self.error = null
          api.get('/v2/users/check', {}, {bearer: responce.data.auth_key})
            .then(responce => {
              cookie.set("auth_key", responce.data.auth_key, {expires: '1Y'});
              self.$store.commit('user/UpdateIdentity', responce.data);
              localStorage.setItem('currentUserId', responce.data.id);
              self.$store.commit('user/CurrentToken', responce.data.auth_key);
              if (responce.data.lang) {
                self.$i18n.locale = responce.data.lang;
                cookie.set("locale", self.$i18n.locale, {expires: '1Y'})
              }
              self.loading = false
              self.$router.push('/')
              document.location.reload()
            }).catch(reason => {
              self.loading = false
              cookie.delete('auth_key')
            })
        return this
        })
        .catch(reason => {
          console.log(reason)
          let _error = []
          try {
            if (!reason.response || !reason.response.data) {
              _error.push('Server response timeout, pleas try to SignIn');
            } else if (reason.response.data.error && reason.response.data.error.extra) {
              for (let i in reason.response.data.error.extra) {
                _error.push(reason.response.data.error.extra[i][0])
              }
            } else if (reason.response.data.error && reason.response.data.error.message) {
              _error.push(reason.response.data.error.message)
            }
            self.error = _error.join('<br>') || reason.response.data.error.message;
            console.error('Load reason', self.error)
          }
          catch (e) {
            console.error(reason)
            console.error(e)
          }
          self.loading = false
        })
      return undefined
    },
    async logout() {
      try {
        // await this.$store.dispatch('user/logout')
        self.$store.commit('user/UpdateIdentity', {});
        self.$store.commit('user/CurrentToken', null);
        cookie.delete('auth_key')
        this.$router.push('/login')
      } catch (e) {
        this.error = e.message
      }
    }
  }
}
</script>

<style>
  .input-color input[type='tel'] {
    background-color: transparent;
  }
</style>
